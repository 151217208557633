
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiInfluencerGroups, ApiBase } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "influencer-groups-information",
  components: {},
  setup() {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      verification_status: 0,
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      __show: {
        created_user: "",
        updated_user: "",
      },
    });

    const options = ref({
      type: [] as any[],
    });
    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: "global_social_connection_type",
      });
      if (data.code == 0) {
        options.value.type = data.data.items;
      }
    };

    const rules = ref({
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getSocialConnectionsyData = () => {
      loading.value = true;
      ApiInfluencerGroups.getGroupLevelInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }

          ApiInfluencerGroups.updateGroupLevel(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteInfluencerGroups = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Influencer Groups?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiInfluencerGroups.deleteGroupLevel({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Influencer Groups has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "influencer-groups" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      getSocialConnectionsyData();
      getDropdownOptions();
    });

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      submit,
      options,
      getSocialConnectionsyData,
      deleteInfluencerGroups,
    };
  },
});
